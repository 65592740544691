import React from 'react';
import Section from '@latitude/section';
import Slider from 'react-slick';
import Icon from '@latitude/icon';
import {
  TEXT_TYPE,
  ICON_VARIANT,
  BUTTON_THEME,
  BUTTON_BG_THEME
} from '@/utils/constants';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './_hero_banner.scss';
import Button from '@/components/lab-components/Button';

const DEFAULT_TRACKING_LOCATION = 'hero';

const SLIDER_CONFIG = {
  dots: true,
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  pauseOnHover: true
};

/**
 * Normalise the name of the Contentful Hero Banner Slide
 * @param {String} name the name of the Contentful Hero Banner Slide
 * @returns the normalised name.
 */
function createPanelName(name) {
  if (typeof name === 'string') {
    const s0 = name.trim().toLowerCase();
    const s1 = s0.replace(/\s+/g, '_');
    const s2 = s1.replace(/[^a-zA-Z0-9_-]/g, '');
    return s2;
  } else {
    return DEFAULT_TRACKING_LOCATION;
  }
}

const ButtonsGroup = ({
  buttons,
  stopWatchCopy = null,
  theme = {},
  trackingLocation = DEFAULT_TRACKING_LOCATION
}) => {
  const { buttonOneTheme, buttonTwoTheme } = theme;
  const buttonOneStyle =
    buttonOneTheme && BUTTON_BG_THEME[buttonOneTheme]
      ? BUTTON_BG_THEME[buttonOneTheme]
      : undefined;
  const buttonTwoStyle =
    buttonTwoTheme && BUTTON_BG_THEME[buttonTwoTheme]
      ? BUTTON_BG_THEME[buttonTwoTheme]
      : undefined;

  function getButtonType(index) {
    if (buttons?.length === 1) {
      return buttonOneStyle || BUTTON_THEME.APPLICATION;
    }
    if (buttons?.length === 2) {
      if (index === 0) {
        return buttonOneStyle || BUTTON_THEME.DEFAULTINVERSE;
      }
      if (index === 1) {
        return buttonTwoStyle || BUTTON_THEME.APPLICATION;
      }
    }
    return BUTTON_THEME.DEFAULTINVERSE;
  }

  const isHomepageButton = buttons && buttons.length > 2;

  return (
    <div className={`hero-buttons-group hero-buttons-${buttons?.length}`}>
      {buttons?.map((button, index) => {
        console.log(
          'trackEventData',
          'location=' + trackingLocation,
          'category=' + 'cta',
          'label=' + button.text
        );
        return (
          button?.url && (
            <Button
              key={index}
              theme={isHomepageButton ? undefined : getButtonType(index)}
              // rel="noopener noreferrer"
              trackEventData={{
                location: trackingLocation,
                label: button.text
              }}
              href={button.url}
              text={button.text}
              isHomepageButton={isHomepageButton}
            />
          )
        );
      })}
      {buttons?.length === 1 &&
        (stopWatchCopy ? (
          <div className="hero-stop-watch">
            <Icon variant={ICON_VARIANT.STOPWATCH} />
            <span>{stopWatchCopy}</span>
          </div>
        ) : (
          <div className="hero-cta-holder" />
        ))}
    </div>
  );
};

const HeroBannerSlide = ({
  name,
  headingSize,
  headingCopy,
  bodyCopy,
  disclaimer,
  image,
  buttons,
  buttonOneTheme,
  buttonTwoTheme,
  stopWatchCopy
}) => {
  const Typography = TEXT_TYPE[headingSize];
  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      )
    }
  };
  const OPTIONS_DISCLAIM = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <span className="content disclaimer">{children}</span>
      )
    }
  };
  const panelName = createPanelName(name);

  return (
    <div className="hero-banner-slide typography-common">
      <div className="hero-slide-content">
        {headingCopy && documentToReactComponents(headingCopy, OPTIONS)}
        {bodyCopy && documentToReactComponents(bodyCopy)}
        {buttons?.length > 0 && (
          <ButtonsGroup
            buttons={buttons}
            stopWatchCopy={stopWatchCopy}
            theme={{ buttonOneTheme, buttonTwoTheme }}
            trackingLocation={panelName}
          />
        )}
        {disclaimer && documentToReactComponents(disclaimer, OPTIONS_DISCLAIM)}
      </div>
      {image?.file?.url && (
        <div className="hero-slide-img">
          <img src={image.file.url} alt={image.file?.fileName} />
        </div>
      )}
    </div>
  );
};

const HeroBanner = ({
  timer,
  slides,
  headingSize = 'Heading 1',
  backgroundColor = ''
}) => {
  const settings = {
    ...SLIDER_CONFIG,
    autoplaySpeed: timer ? timer : 5000
  };

  return (
    <Section
      className="hero-banner"
      css={backgroundColor && { background: `${backgroundColor} !important` }}
    >
      <Slider {...settings}>
        {slides?.map((slide, index) => {
          return (
            <HeroBannerSlide {...slide} headingSize={headingSize} key={index} />
          );
        })}
      </Slider>
    </Section>
  );
};

export default HeroBanner;
