/* eslint-disable global-require */
import React from 'react';

import { MicrositeHeader } from '@latitude/microsite-header';

const APPLY_NOW_URL = 'https://cards.latitudefinancial.com/28degrees';

function DegreesCardMicrositeHeader({ applyNowUrl = APPLY_NOW_URL, data }) {
  const navItems = data?.navItems || [
    {
      id: 'home',
      label: 'Home',
      trackId: 'nav-home',
      href: '/credit-cards/28-degrees/'
    },
    {
      id: 'travel-inspiration',
      label: 'Travel Inspiration',
      trackId: 'nav-travel-inspiration',
      href: '/credit-cards/28-degrees/travel-inspiration/'
    },
    {
      id: 'balance-transfer',
      label: 'Balance Transfer',
      trackId: 'nav-balance-transfer',
      href: '/credit-cards/28-degrees/balance-transfer/'
    },
    {
      id: 'faqs',
      label: 'FAQs',
      trackId: 'nav-faqs',
      href: '/credit-cards/28-degrees/faqs/'
    },
    {
      id: 'help',
      label: 'Help',
      trackId: 'help',
      href: 'https://latitudefs.zendesk.com/hc/en-au/'
    }
  ];

  return (
    <MicrositeHeader
      contentfulID={data?.id}
      data={navItems}
      ctaHref={data?.cta?.url || applyNowUrl}
      trademark={data?.trademark !== false}
      microSiteName={data?.navTitle || '28° Global'}
      target='_self'
    />
  );
}

export default DegreesCardMicrositeHeader;
